<template>
  <div>
    <v-app class="app" v-if="showApp" :key="reCreateApp + showApp">
      <transition name="fade" mode="out-in">
        <navbar-app @recreate="recreate" v-if="!isLinkPage"> </navbar-app>
      </transition>
      <snackbar></snackbar>

      <div></div>

      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
      <footer-app v-if="!isLinkPage" class="footer"></footer-app>
      <whatsapp-btn></whatsapp-btn>
      <transition name="fade" mode="out-in">
        <cookies-app
          v-if="viewCookies && !cookiesAccepted"
          @close="viewCookies = false"
        ></cookies-app>
      </transition>

      <!-- login form -->
      <v-dialog
        v-model="verifyDialog"
        max-width="500px"
        content-class="radius-25 "
      >
        <verify-phone
          :key="verifyDialog"
          @close="verifyDialog = false"
        ></verify-phone>
      </v-dialog>
    </v-app>
    <div class="loading-absolute" v-if="loadingLang">
      <v-progress-circular
        indeterminate
        color="grey-lighten-5"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import NavbarApp from "@/layout/Navbar";
import FooterApp from "@/layout/Footer";
import { mapGetters } from "vuex";
import Snackbar from "@/components/global/Snackbar";
import WhatsappBtn from "./components/global/WhatsappBtn.vue";
import CookiesApp from "@/layout/Cookies";
import VerifyPhone from "./components/dialogs/VerifyPhone.vue";

export default {
  name: "App",
  components: {
    NavbarApp,
    Snackbar,
    FooterApp,
    WhatsappBtn,
    CookiesApp,
    VerifyPhone,
  },
  data: () => ({
    showApp: false,
    reCreateApp: false,
    viewCookies: true,
    verifyDialog: false,
  }),
  computed: {
    ...mapGetters(["showNavbar", "changeLang", "loadingLang", "verifyOpen"]),
    cookiesAccepted() {
      return localStorage.getItem("cookies");
    },
    isLinkPage() {
      if (
        this.$route.name == "footer-section" ||
        this.$route.name == "header-section"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    reCreateApp: {
      async handler() {
        await this.handleTranslate();
      },
    },
    changeLang: {
      async handler() {
        await this.handleTranslate();
      },
    },
    verifyDialog: {
      handler() {
        if (this.verifyOpen && !this.verifyDialog)
          this.$store.dispatch("verifyPhoneHandler", {
            dialog: false,
            phone: "",
          });
      },
    },
    verifyOpen: {
      handler() {
        if (this.verifyOpen) {
          this.verifyDialog = true;
        }
      },
    },
  },
  methods: {
    scrollHandler() {
      if (window.pageYOffset < 1900 || window.pageYOffset > 2400) {
        this.$store.dispatch("showCreateBtn", true);
      } else {
        this.$store.dispatch("showCreateBtn", false);
      }

      // show navbar
      if (window.pageYOffset > 80) {
        this.$store.dispatch("showNavbarHandler", true);
      } else {
        this.$store.dispatch("showNavbarHandler", false);
      }
    },
    recreate() {
      this.reCreateApp = !this.reCreateApp;
    },
    async handleTranslate() {
      this.$axios.defaults.baseURL = "https://b2c-api.bondai.io/api/v1";
      this.showApp = false;

      const html = document.documentElement;
      const body = document.querySelector("body");

      let queryLang = "";
      let ourLangs = ["en", "ar"];
      let localLang = localStorage.getItem("language");

      let resKeys;
      if (ourLangs.includes(this.$route.params.lang)) {
        queryLang = this.$route.params.lang;
      } else {
        if (localLang) {
          queryLang = localLang;
        } else {
          let res = await this.$axios.post("utilities/init");
          if(res.data?.value){
            queryLang = res.data.value.languageCode;
          }else{
            queryLang = "en";
          }
        }
      }
      resKeys = await this.$axios.get(
        `/sites/63a47451f74d056b5da185ad/translations/` + queryLang
      );
      if (queryLang == "ar") {
        this.setSiteLang("ar", true, "rtl", html, body);
        this.$vuetify.rtl = true;
      } else {
        this.setSiteLang(queryLang, false, "ltr", html, body);
      }

      this.$i18n.locale = queryLang;

      this.showApp = true;
      let obj = {};
      resKeys.data.value.forEach((key) => {
        obj[key.key] = key.value;
      });
      this.$store.dispatch("setTranslate", obj);
      if (!localStorage.getItem("currency")) {
        localStorage.setItem("currency", "SAR");
      }
      this.$axios.defaults.baseURL = this.$baseURL;
    },
    setSiteLang(lang, rtlBoolean, rtlDir, html, body) {
      localStorage.setItem("language", lang);
      this.$vuetify.rtl = rtlBoolean;
      this.$i18n.locale = lang;
      html.setAttribute("lang", lang);
      body.setAttribute("dir", rtlDir);
    },
  },
  async created() {
    setTimeout(async () => {
      await this.handleTranslate();
    }, 400);
  },
  mounted() {
    setTimeout(() => {
      let element = document.getElementsByClassName(
        this.$route.query.translationKey
      )[0];
      if (element) {
        element.style.background = "#fc5b353d !important";
        const y = element.getBoundingClientRect().top + window.scrollY - 100;
        window.scroll({
          top: y,
          behavior: "smooth",
        });
      }
    }, 1000);
  },
};
</script>

<style lang="scss">
.app {
  overflow-x: unset !important;
  .custom-app {
    min-height: 700px;
    background: #f5f5f5 !important;
  }
}
.loading-absolute {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
}
</style>
