import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    text: "",
    color: "",
    typeToast: "",
    timeout: "",
    loading: false,
    loadingLang: false,
    userLogged: true,
    fixedBtn: true,
    showNavbar: false,
    createBtn: true,
    translate: {},
    userDetails: {},
    travelers: [],
    favList: [],
    favListUpdated: false,
    travelersCount: 0,
    bookingDetails: {},
    token: "",
    changeLang: false,
    verifyOpen: false,
    verifyPhone: {
      phone: '',
      regionCode: ''
    },
    isChangePhone: false,
    reRenderUser: false,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    loadingLang(state) {
      return state.loadingLang;
    },
    typeToast(state) {
      return state.typeToast;
    },
    getLayoutPage(state) {
      return state.layoutPage;
    },
    userLogged(state) {
      return state.userLogged;
    },
    fixedBtn(state) {
      return state.fixedBtn;
    },
    showNavbar(state) {
      return state.showNavbar;
    },
    createBtn(state) {
      return state.createBtn;
    },
    translate(state) {
      return state.translate;
    },
    userDetails(state) {
      return state.userDetails;
    },
    travelers(state) {
      return state.travelers;
    },
    travelersCount(state) {
      return state.travelersCount;
    },
    bookingDetails(state) {
      return state.bookingDetails;
    },
    favList(state) {
      return state.favList;
    },
    favListUpdated(state) {
      return state.favListUpdated;
    },
    token(state) {
      return state.token || localStorage.getItem("token");
    },
    changeLang(state) {
      return state.changeLang;
    },
    verifyOpen(state) {
      return state.verifyOpen;
    },
    verifyPhone(state) {
      return state.verifyPhone;
    },
    isChangePhone(state) {
      return state.isChangePhone;
    },
    reRenderUser(state) {
      return state.reRenderUser;
    },
  },
  mutations: {
    SHOW_MESSAGE(state, payload) {
      state.text = payload.text;
      state.color = payload.color;
      state.typeToast = payload.typeToast;
      state.timeout = payload.timeout;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_LOADING_LANG(state, payload) {
      state.loadingLang = payload;
    },
    SET_FIXEDBTN(state, payload) {
      state.fixedBtn = payload;
    },
    SET_SHOWNAVBAR(state, payload) {
      state.showNavbar = payload;
    },
    SHOW_CREATE_BTN(state, payload) {
      state.createBtn = payload;
    },
    SET_TRANSLATE(state, payload) {
      state.translate = payload;
    },
    SET_TOKEN(state, payload) {
      state.token = payload.token;
      localStorage.setItem("token", payload.token);
    },
    REMOVE_USER(state) {
      state.token = "";
      state.userDetails = "";
      localStorage.removeItem("token");
      router.go();
    },
    SET_USER_DETAILS(state, payload) {
      state.userDetails = payload;
      state.favList = payload.favouriteActivities;
    },
    SET_FAV_LIST_SYNC(state, payload) {
      state.favList = payload;
      state.favListUpdated = !state.favListUpdated;
    },
    SET_TRAVELERS(state, payload) {
      state.travelers = payload.travelers;
      state.travelersCount = payload.travelersCount;
      state.bookingDetails = payload.bookingDetails;
    },
    CHANGE_LANG(state) {
      state.changeLang = !state.changeLang;
      router.go()
    },
    VERIFY_PHONE(state, payload) {
      state.verifyOpen = payload.dialog;
      state.verifyPhone = payload.phone;
      state.isChangePhone = payload.isChange;
    },
    RE_RENDER_USER(state) {
      state.reRenderUser = !state.reRenderUser;
    },
  },
  actions: {
    showSnack({ commit }, payload) {
      commit("SHOW_MESSAGE", payload);
    },
    loading({ commit }, payload) {
      commit("SET_LOADING", payload);
    },
    loadingLang({ commit }, payload) {
      commit("SET_LOADING_LANG", payload);
    },
    fixedBtnHandler({ commit }, payload) {
      commit("SET_FIXEDBTN", payload);
    },
    showNavbarHandler({ commit }, payload) {
      commit("SET_SHOWNAVBAR", payload);
    },
    showCreateBtn({ commit }, payload) {
      commit("SHOW_CREATE_BTN", payload);
    },
    setTranslate({ commit }, payload) {
      commit("SET_TRANSLATE", payload);
    },
    setToken({ commit }, payload) {
      commit("SET_TOKEN", payload);
    },
    setCategoriesImage(_, list) {
      return list.map((category) => {
        category.img = require("@/assets/images/categories/Short_break.svg");
        
        if (category.code == "GOLF")
          category.img = require("@/assets/images/categories/Golf.svg");
        if (category.code == "SPECTATOR_SPORTS")
          category.img = require("@/assets/images/categories/Spectator_sports.svg");
        if (category.code == "PAINTBALL")
          category.img = require("@/assets/images/categories/Paintball.svg");
        if (category.code == "RAIL_PASS")
          category.img = require("@/assets/images/categories/rail_pass.svg");
        if (category.code == "FESTIVAL")
          category.img = require("@/assets/images/categories/Festival.svg");
        if (category.code == "SKIING")
          category.img = require("@/assets/images/categories/Skiing.svg");
        if (category.code == "AIRPORT_LOUNGE")
          category.img = require("@/assets/images/categories/Airport_Lounge.svg");
        if (category.code == "SNOWMOBILE_TOUR")
          category.img = require("@/assets/images/categories/Snowmobile_Tour.svg");
        if (category.code == "GLACIER_HIKING")
          category.img = require("@/assets/images/categories/Glacier_hiking.svg");
        if (category.code == "SEGWAY_TOUR")
          category.img = require("@/assets/images/categories/Segway_tour.svg");
        if (category.code == "RUNNING")
          category.img = require("@/assets/images/categories/Running.svg");












        if (category.code == "LAYOVER_TOURS")
          category.img = require("@/assets/images/categories/Night-Activities.svg");
        if (category.code == "NIGHTLIFE")
          category.img = require("@/assets/images/categories/Night-Activities.svg");
        if (category.code == "SKIP_THE_LINE")
          category.img = require("@/assets/images/categories/Night-Activities.svg");
        if (category.code == "DAY_TRIPS_AND_EXCURSIONS")
          category.img = require("@/assets/images/categories/Morning-Activities.svg");
        if (category.code == "SUN_AND_BEACH")
          category.img = require("@/assets/images/categories/Morning-Activities.svg");
        if (category.code == "SHORE_EXCURSIONS")
          category.img = require("@/assets/images/categories/Morning-Activities.svg");
        if (category.code == "WEDDING_AND_HONEYMOON")
          category.img = require("@/assets/images/categories/Family-Activities.svg");
        if (category.code == "LUXURY_AND_SPECIAL_OCCASIONS")
          category.img = require("@/assets/images/categories/Family-Activities.svg");
        if (category.code == "CULTURAL_AND_THEME_TOURS")
          category.img = require("@/assets/images/categories/cultural.svg");
        if (category.code == "MUSEUMS_AND_EXHIBITIONS")
          category.img = require("@/assets/images/categories/Historical.svg");
        if (category.code == "CITY_TOURS")
          category.img = require("@/assets/images/categories/City_Tours.svg");
        if (category.code == "ADVENTURE")
          category.img = require("@/assets/images/categories/Camping.svg");
        if (category.code == "SAFARI_AND_WILDLIFE")
          category.img = require("@/assets/images/categories/Camping.svg");
        if (category.code == "CULINARY")
          category.img = require("@/assets/images/categories/Culinary.svg");
        if (category.code == "BIKE_TOUR")
          category.img = require("@/assets/images/categories/Cycling.svg");
        if (category.code == "MOUNTAIN_BIKE")
          category.img = require("@/assets/images/categories/Mountain_bike_colored.svg");
        if (category.code == "EBIKE_TOUR")
          category.img = require("@/assets/images/categories/Cycling.svg");
        if (category.code == "MULTISPORT")
          category.img = require("@/assets/images/categories/Cycling.svg");
        if (category.code == "BIRDWATCHING")
          category.img = require("@/assets/images/categories/Star-Monogazing.svg");
        if (category.code == "PHOTOGRAPHY")
          category.img = require("@/assets/images/categories/Photography_colored.svg");
        if (category.code == "SIGHTSEEING_ATTRACTION")
          category.img = require("@/assets/images/categories/Star-Monogazing.svg");
        if (category.code == "SIGHTSEEING")
          category.img = require("@/assets/images/categories/Star-Monogazing.svg");
        if (category.code == "CAVING")
          category.img = require("@/assets/images/categories/Closed-trip.svg");
        if (category.code == "HIKING")
          category.img = require("@/assets/images/categories/Hiking.svg");
        if (category.code == "WALKING_TOUR")
          category.img = require("@/assets/images/categories/Closed-trip.svg");
        if (category.code == "CLIMBING")
          category.img = require("@/assets/images/categories/Hiking.svg");
        if (category.code == "FISHING")
          category.img = require("@/assets/images/categories/Zoos_and_Aquariums.svg");
        if (category.code == "SEA_ANGLING")
          category.img = require("@/assets/images/categories/Sea_Angling.svg");
        if (category.code == "SAILING_OR_BOAT_TOUR")
          category.img = require("@/assets/images/categories/Boating.svg");
        if (category.code == "DOLPHIN_OR_WHALEWATCHING")
          category.img = require("@/assets/images/categories/Zoos_and_Aquariums.svg");
        if (category.code == "WATER")
          category.img = require("@/assets/images/categories/Boating.svg");
        if (category.code == "RAFTING")
          category.img = require("@/assets/images/categories/Rafting.svg");
        if (category.code == "MINI_CRUISE")
          category.img = require("@/assets/images/categories/Bus_or_Minivan_Tour.svg");
        if (category.code == "DIVING")
          category.img = require("@/assets/images/categories/SNORKELING.svg");
        if (category.code == "SNORKELING")
          category.img = require("@/assets/images/categories/SNORKELING.svg");
        if (category.code == "ATV_OR_QUAD_TOUR")
          category.img = require("@/assets/images/categories/atv_or_quad_tour.svg");
        if (category.code == "SEAT_IN_COACH_TOUR")
          category.img = require("@/assets/images/categories/Offroading.svg");
        if (category.code == "TROLLEY_TOURS")
          category.img = require("@/assets/images/categories/Offroading.svg");
        if (category.code == "SELF_DRIVE_TOUR")
          category.img = require("@/assets/images/categories/Private_Roundtrip.svg");
        if (category.code == "HORSEBACK_RIDING")
          category.img = require("@/assets/images/categories/Horse_carriage_ride.svg");
        if (category.code == "HORSE_CARRIAGE_RIDE")
          category.img = require("@/assets/images/categories/Horse_carriage_ride.svg");
        if (category.code == "PRIVATE_CAR_TOUR")
          category.img = require("@/assets/images/categories/Private_Roundtrip.svg");
        if (category.code == "HOP_ON_HOP_OFF_TOUR")
          category.img = require("@/assets/images/categories/Offroading.svg");
        if (category.code == "CLASSIC_CAR_TOURS")
          category.img = require("@/assets/images/categories/Offroading.svg");
        if (category.code == "TRANSFERS_AND_GROUND_TRANSPORT")
          category.img = require("@/assets/images/categories/Transfers_and_ground_transport.svg");
        if (category.code == "BUS_OR_MINIVAN_TOUR")
          category.img = require("@/assets/images/categories/Bus_or_Minivan_Tour.svg");
        if (category.code == "SHOPPING")
          category.img = require("@/assets/images/categories/shopping.svg");
        if (category.code == "TV_AND_MOVIES")
          category.img = require("@/assets/images/categories/SPA.svg");
        if (category.code == "CITY_BREAK")
          category.img = require("@/assets/images/categories/City_Tours.svg");
        if (category.code == "SHOWS_AND_MUSICALS")
          category.img = require("@/assets/images/categories/Romantic.svg");
        if (category.code == "THEME_PARKS")
          category.img = require("@/assets/images/categories/Romantic.svg");
        if (category.code == "NATURE")
          category.img = require("@/assets/images/categories/Nature.svg");
        if (category.code == "EDUCATIONAL_TOUR")
          category.img = require("@/assets/images/categories/Farm.svg");
        if (category.code == "CLASSES_AND_WORKSHOPS")
          category.img = require("@/assets/images/categories/Farm.svg");
        if (category.code == "KAYAKING")
          category.img = require("@/assets/images/categories/Kayaking.svg");

        if (category.code == "AIR_OR_HELICOPTER_TOUR")
          category.img = require("@/assets/images/categories/Air_or_Helicopter_Tour.svg");

        if (category.code == "ADRENALINE_AND_EXTREME")
          category.img = require("@/assets/images/categories/Adrenaline_and_Extreme.svg");

        if (category.code == "ZOOS_AND_AQUARIUMS")
          category.img = require("@/assets/images/categories/Zoos_and_Aquariums.svg");

        if (category.code == "ARTS_AND_CULTURE")
          category.img = require("@/assets/images/categories/Arts_and_Culture.svg");

        if (category.code == "ESCAPE_GAME")
          category.img = require("@/assets/images/categories/Escape_Game.svg");

        if (category.code == "RAIL_PASS")
          category.img = require("@/assets/images/categories/rail_pass.svg");

        if (category.code == "UNDERGROUND_TOURS")
          category.img = require("@/assets/images/categories/Underground_Tours.svg");

        if (category.code == "CANOEING")
          category.img = require("@/assets/images/categories/Canoeing.svg");

        if (category.code == "FISHING")
          category.img = require("@/assets/images/categories/fishing.svg");

        if (category.code == "LANGUAGE_TOUR")
          category.img = require("@/assets/images/categories/Language_tour.svg");

        if (category.code == "PLANTATION_TOUR")
          category.img = require("@/assets/images/categories/plantation_tour.svg");

        if (category.code == "VIP_AND_EXCLUSIVE")
          category.img = require("@/assets/images/categories/Vip_And_Exclusive.svg");

        category.active = false;
        return category;
      });
    },
    setTravelers({ commit }, payload) {
      commit("SET_TRAVELERS", payload);
    },
    async getUserDetails({ commit, getters }) {
      let res = await axios.get("/users/details", {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      });

      commit("SET_USER_DETAILS", res.data.value);
      return res.data.value;
    },
    logoutHandler({ commit }) {
      commit("REMOVE_USER");
    },
    changeLangHandler({ commit }) {
      commit("CHANGE_LANG");
    },
    verifyPhoneHandler({ commit }, payload) {
      commit("VERIFY_PHONE", payload);
    },
    reRenderUser({ commit }) {
      commit("RE_RENDER_USER");
    },
  },
  modules: {},
});
