import Vue from "vue";
import axios from "axios";
import store from "../store";
// Vue.prototype.$baseURL = "https://bondai-b2c-api-dev-test.azurewebsites.net/api/v1";
Vue.prototype.$baseURL = "https://b2c-api.bondai.io/api/v1";


axios.defaults.baseURL = Vue.prototype.$baseURL;

window.axios = axios;
Vue.prototype.$axios = axios;
export default async () => {
  // show loader in each request
  window.axios.interceptors.request.use(
    (confiq) => {
      store.dispatch('loading', true)
    return confiq;
  },
  (erorr) => {
      return erorr;
    }
  );

  window.axios.interceptors.response.use(
    function (response) {
      store.dispatch('loading', false)
      return response;
    },
    async function (error) {
      store.dispatch('loading', false)
      // response with status code > 200 is here
      return error.response.data;
    }
  );
};